<template>
  <v-container fill-height class="forbidden-page">
    <v-row align="center" justify="center">
      <v-col sm="6" md="4">
        <v-card elevation="2">
          <v-card-title>403</v-card-title>
          <v-card-text>
            Sorry, your access is refused.
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              color="primary"
              class="logout-btn"
              @click="onLogoutClick()"
              >Logout</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "ForbiddenPage",

  computed: {
    ...mapState("coreModule", ["drawer"]),
  },

  methods: {
    async onLogoutClick() {
      try {
        await this.$store.dispatch("userModule/signOut");
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },
  },

  mounted() {
    if (this.drawer) {
      this.$store.commit("coreModule/toggleDrawer");
    }
  },
};
</script>
